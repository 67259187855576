<template>
  <div class="hometitle">
    <div class="bigTitle">{{ title }}</div>
    <div class="Timeshow" v-if="showTimetitle">{{ Timedate }}</div>
    <div class="rightBox" v-if="showRightbox">
      <ul>
        <li>
          <div class="colorhorizontal styleColorsetone"></div>
          <span>呼叫数</span>
        </li>
        <li>
          <span class="colorhorizontal styleColorsettwo"></span>
          <span>接通数</span>
        </li>
        <li>
          <span class="colorhorizontal styleColorsetthree"></span>
          <span>动态数</span>
        </li>
        <li class="lastLi">
          <div class="rightBtn">
            <span
              v-if="showBtnClass"
              class="weekBtn"
              @click="ClickRightBtn('week')"
              >本周</span
            >
            <span
              v-if="!showBtnClass"
              class="monBtn"
              @click="ClickRightBtn('week')"
              >本周</span
            >

            <span
              v-if="showBtnClass"
              class="monBtn"
              @click="ClickRightBtn('month')"
              >当月</span
            >
            <span
              v-if="!showBtnClass"
              class="weekBtn"
              @click="ClickRightBtn('month')"
              >当月</span
            >
          </div>
        </li>
      </ul>
    </div>
    <div class="rightBoxphone" v-if="showRightboxphone">
      <ul>
        <li>
          <div class="colorhorizontal styleColorsetfour"></div>
          <span>总通时</span>
        </li>
        <li>
          <span class="colorhorizontal styleColorsetfive"></span>
          <span>有效通时</span>
        </li>
        <li class="lastLi">
          <div class="rightBtn">
            <span
              v-if="showBtnClassphone"
              class="weekBtn"
              @click="ClickRightBtnphone('week')"
              >本周</span
            >
            <span
              v-if="!showBtnClassphone"
              class="monBtn"
              @click="ClickRightBtnphone('week')"
              >本周</span
            >

            <span
              v-if="showBtnClassphone"
              class="monBtn"
              @click="ClickRightBtnphone('month')"
              >当月</span
            >
            <span
              v-if="!showBtnClassphone"
              class="weekBtn"
              @click="ClickRightBtnphone('month')"
              >当月</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Bus from "@/utils/bus";

export default {
  name: "HomeTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    showRightbox: {
      type: Boolean,
    },
    showRightboxphone: {
      type: Boolean,
    },
    showTimetitle: {
      type: Boolean,
    },
  },
  data() {
    return {
      showBtnClass: true, //控制第五个按钮样式
      showBtnClassphone: true, //控制第六个按钮样式
      Timedate: null,
    };
  },
  mounted() {
    this.getNowFormatDate()
  },
  methods: {
    ClickRightBtn(val) {
      this.$emit("getFivecallClick", val);
      this.showBtnClass = !this.showBtnClass;
    },
    ClickRightBtnphone(val) {
      this.$emit("getSixcallClick", val);
      this.showBtnClassphone = !this.showBtnClassphone;
    },
    // 获取当天日期
    getNowFormatDate() {
      let date = new Date();
      let seperator1 = "-";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + seperator1 + month + seperator1 + strDate;
      this.Timedate = currentdate;
    },
  },
};
</script>

<style lang="scss" scoped>
.hometitle {
  height: 42px;
  background: #f6faff;
  line-height: 42px;
  position: relative;
  .bigTitle {
    display: inline-block;
    font-size: 16px;
    color: #181818;
    padding-left: 18px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
  }
  .Timeshow {
    margin-left: 11px;
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    color: #181818;
  }
  .rightBox {
    display: inline-block;
    width: 70%;
    position: absolute;
    right: 0;
    > ul {
      display: flex;
      padding-left: 0px !important;
      > li {
        width: 22%;
        .colorhorizontal {
          height: 3px;
          width: 30px;
          display: inline-block;
          margin-right: 6px;
          margin-bottom: 2px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #666;
        }
        .rightBtn {
          width: 100%;
          height: 24px;
          line-height: 24px;
          cursor: pointer;
          .weekBtn {
            width: 49%;
            text-align: center;
            display: inline-block;
            background-color: #337aff;
            border: 1px solid #337aff;
            color: #fff;
          }
          .monBtn {
            width: 49%;
            border: 1px solid #337aff;
            text-align: center;
            display: inline-block;
            color: #666666;
          }
        }
      }
      .lastLi {
        width: 30%;
        padding-top: 10px;
      }
    }
  }
  .rightBoxphone {
    display: inline-block;
    width: 59%;
    position: absolute;
    right: 0;
    > ul {
      display: flex;
      padding-left: 0px !important;
      > li {
        width: 30%;
        .colorhorizontal {
          height: 3px;
          width: 30px;
          display: inline-block;
          margin-right: 6px;
          margin-bottom: 2px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #666;
        }
        .rightBtn {
          width: 100%;
          height: 24px;
          line-height: 24px;
          cursor: pointer;
          .weekBtn {
            width: 49%;
            text-align: center;
            display: inline-block;
            background-color: #337aff;
            border: 1px solid #337aff;
            color: #fff;
          }
          .monBtn {
            width: 49%;
            border: 1px solid #337aff;
            text-align: center;
            display: inline-block;
            color: #666666;
          }
        }
      }
      .lastLi {
        width: 35%;
        padding-top: 10px;
      }
    }
  }
}
.styleColorsetone {
  background-color: #337aff;
}
.styleColorsettwo {
  background-color: #00c4fa;
}
.styleColorsetthree {
  background-color: #2ad29e;
}
.styleColorsetfour {
  background-color: #28c2f8;
}
.styleColorsetfive {
  background-color: #8977ff;
}
</style>