var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fourBody" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            "header-cell-style": {
              fontSize: "13px",
              color: "#000",
              backgroundColor: "#e5f2ff"
            },
            "header-cell-class-name": "normal-table-header",
            "cell-style": { fontSize: "13px", color: "#666" },
            data: _vm.tableData,
            height: "260",
            "tooltip-effect": "dark"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              prop: "name",
              label: "类型",
              width: "70",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              prop: "address",
              label: "消息内容",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              prop: "date",
              label: "通知时间",
              width: "120",
              align: "left"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }