<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-11-10 16:39:23
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-11 09:04:45
-->
<template>
  <div>
    <div class="today">
      <el-dropdown class="drop" style="width: auto!important" @command="statisChange">
        <span style="font-size: 16px">{{staisTitle}}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="day">今日</el-dropdown-item>
          <el-dropdown-item command="week">本周</el-dropdown-item>
          <el-dropdown-item command="month">本月</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-row class="grade margin-top-8">
      <el-col :span="8" v-for="(item, index) in inforCardData" :key="index">
        <div>
          <i :style="{fontSize: '30px', color: item.color}" :class="[item.icon]"></i>
        </div>
        <div>
          <p style="font-size: 18px">{{ item.count }}</p>
          <p>{{ item.title }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { reqPersonStatis } from "api/home";
export default {
  name: "grade",
  props: {},
  data() {
    return {
      staisTitle: "今日战果",
      callStatisData: {
        seatAnswer: 0,
        customerAnswer: 0,
        answerRate: "0%",
        talkTime: "00:00:00",
        validTime: "00:00:00",
        averageTime: "00:00:00"
      }
    };
  },
  computed: {
    inforCardData() {
      return [
        {
          title: "点击外呼：当天拨出电话的次数",
          icon: "el-icon-user-solid",
          count: this.callStatisData.seatAnswer,
          color: "#2d8cf0"
        },
        {
          title: "客户有效接听:当天客户接听的大于等于30S的次数",
          icon: "el-icon-question",
          count: this.callStatisData.customerAnswer,
          color: "#ff9900"
        },
        {
          title: "客户的有效接听率:客户有效接听/点击外呼",
          icon: "el-icon-s-promotion",
          count: this.callStatisData.answerRate,
          color: "#9A66E4"
        },
        {
          title: "总通话时长：当天外呼和回呼双方接通开始的总通时",
          icon: "el-icon-share",
          count: this.callStatisData.talkTime,
          color: "#ed3f14"
        },
        {
          title: "有效总通时：当天外呼和回呼双方接通大于等于30S的总通时",
          icon: "el-icon-aim",
          count: this.callStatisData.validTime,
          color: "#19be6b"
        },
        {
          title: "平均通时：总通话时长/总客户接听",
          icon: "el-icon-chat-round",
          count: this.callStatisData.averageTime,
          color: "#E46CBB"
        }
      ];
    }
  },
  mounted() {
    this.loadPersonStatis('day')
  },
  methods: {
    statisChange(name) {
      this.loadPersonStatis(name);
      switch (name) {
        case "week":
          this.staisTitle = "本周";
          break;
        case "month":
          this.staisTitle = "本月";
          break;
        default:
          this.staisTitle = "今日战果";
      }
    },
    // 统计通话信息
    loadPersonStatis(type) {
      reqPersonStatis(type).then(data => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.callStatisData = result;
        } else {
          this.$Message.error(msg);
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.grade {
    padding: 10px 20px;
}
.today {
  height: 42px;
  line-height: 42px;
  background: #f6faff;
  padding-left: 10px;
}
.drop {
  width: 0!important;
}
.el-col-8{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 124px;

  p{
    text-align: center;
    font-size: 12px;
    transform: scale(0.8);
  }
}

</style>