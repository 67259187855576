var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fiveBody" }, [
    _vm.typeClick == "week"
      ? _c("div", {
          style: { width: "100%", height: "100%" },
          attrs: { id: "myChartfive" }
        })
      : _c("div", {
          style: { width: "100%", height: "100%" },
          attrs: { id: "myChartfivemonth" }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }