<template>
  <div class="fiveBody">
    <div
      id="myChartfive"
      :style="{ width: '100%', height: '100%' }"
      v-if="typeClick == 'week'"
    ></div>
    <div
      id="myChartfivemonth"
      :style="{ width: '100%', height: '100%' }"
      v-else
    ></div>
  </div>
</template>

<script>
import { getHomecallStatistical } from "api/home";

export default {
  name: "fiveBody",
  props: {},
  data() {
    return {
      typeClick: "week",
    };
  },
  methods: {
    //   从父组件调用的
    _getHomecall(val) {
      getHomecallStatistical({ type: val }).then((res) => {
        if (val == "week") {
          this.typeClick = "week";
          this.$nextTick(() => {
            this.drawfiveLine(res.result);
          });
        } else {
          this.typeClick = "month";
          this.$nextTick(() => {
            this.drawfiveLinemonth(res.result);
          });
        }
      });
    },
    _getHomecallStatistical() {
      // let params = {
      //   type: this.typeClick,
      // };
      getHomecallStatistical({ type: this.typeClick }).then((res) => {
        this.drawfiveLine(res.result);
      });
    },
    drawfiveLine(obj) {
      // 基于准备好的dom，初始化echarts实例
      let callStatistical = obj.callStatistical; //呼叫数
      let answerStatistical = obj.answerStatistical; //接通数
      let fllowStatistical = obj.fllowStatistical; //动态数

      let TimeArray = []; //装x轴时间
      let callStatis = []; //呼叫数val
      let answerStatis = []; //接通数val
      let fllowStatis = []; //动态数val

      callStatistical.map((item) => {
        item.day = item.day.slice(5, 10);
        TimeArray.push(item.day);
        callStatis.push(item.val);
      });
      answerStatistical.map((i) => {
        answerStatis.push(i.val);
      });
      fllowStatistical.map((j) => {
        fllowStatis.push(j.val);
      });

      let myChart = this.$echarts.init(document.getElementById("myChartfive"));

      // 绘制图表
      myChart.setOption({
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              // backgroundColor: "#6a7985",
            },
          },
        },

        xAxis: [
          {
            type: "category",
            data: TimeArray,
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 1,
            minInterval: 1,
          },
        ],
        series: [
          {
            name: "呼叫数",
            type: "line",
            // 展示曲线上的点
            showSymbol: true,
            // 让曲线更加圆滑
            smooth: true,
            lineStyle: {
              color: "rgb(83,143,255)",
              normal: {
                color: "rgb(83,143,255)",
              },
            },
            // label: {
            //   show: true,
            //   position: "top",
            // },
            itemStyle: {
              normal: {
                color: "rgb(83,143,255)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(83,143,255, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(83,143,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            // data: [0, 0, 0],
            data: callStatis,
          },
          {
            name: "接通数",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(0,196,250)",
              normal: {
                color: "rgb(0,196,250)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(0,196,250)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,196,250, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,196,250, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            //  data: [0, 2.5, 0],
            data: answerStatis,
          },
          {
            name: "动态数",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(42,210,158) ",
              normal: {
                color: "rgb(42,210,158)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(42,210,158)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(42,210,158, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(42,210,158, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            // data: [1, 1, 0],
            data: fllowStatis,
          },
        ],
      });
    },
    drawfiveLinemonth(obj) {
      // 基于准备好的dom，初始化echarts实例
      let callStatistical = obj.callStatistical; //呼叫数
      let answerStatistical = obj.answerStatistical; //接通数
      let fllowStatistical = obj.fllowStatistical; //动态数

      let TimeArray = []; //装x轴时间
      let callStatis = []; //呼叫数val
      let answerStatis = []; //接通数val
      let fllowStatis = []; //动态数val

      callStatistical.map((item) => {
        item.day = item.day.slice(5, 10);
        TimeArray.push(item.day);
        callStatis.push(item.val);
      });
      answerStatistical.map((i) => {
        answerStatis.push(i.val);
      });
      fllowStatistical.map((j) => {
        fllowStatis.push(j.val);
      });

      let myChart = this.$echarts.init(
        document.getElementById("myChartfivemonth")
      );

      // 绘制图表
      myChart.setOption({
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        xAxis: [
          {
            type: "category",
            data: TimeArray,
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 1,
            minInterval: 1,
          },
        ],
        series: [
          {
            name: "呼叫数",
            type: "line",
            // 展示曲线上的点
            showSymbol: true,
            // 让曲线更加圆滑
            smooth: true,
            lineStyle: {
              color: "rgb(83,143,255)",
              normal: {
                color: "rgb(83,143,255)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(83,143,255)",
              }
            },
            // label: {
            //   show: false,
            //   position: "top",
            // },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(83,143,255, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(83,143,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: callStatis,
            // data: [0, 10, 0, 20, 0, 60, 0, 55, 6, 10, 65, 100, 0, 200],
          },
          {
            name: "接通数",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(0,196,250)",
              normal: {
                color: "rgb(0,196,250)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(0,196,250)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0,196,250, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,196,250, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            // data: [156, 56, 50, 66, 9],
            data: answerStatis,
          },
          {
            name: "动态数",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(42,210,158) ",
              normal: {
                color: "rgb(42,210,158)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(42,210,158)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(42,210,158, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(42,210,158, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: fllowStatis,
            // data: [5, 56, 50, 106, 99],
          },
        ],
      });
    },
  },
  mounted() {
    this._getHomecallStatistical();
  },
};
</script>

<style lang="scss" scoped>
.fiveBody {
  width: 100%;
  padding: 10px 20px;
  height: calc(100% - 46px);
  position: relative;
}
</style>