<template>
  <div class="sixBody">
    <div
      id="myChartsix"
      v-if="typeClicksix == 'week'"
      :style="{ width: '100%', height: '100%' }"
    ></div>
    <div
      id="myChartsixmonth"
      v-else
      :style="{ width: '100%', height: '100%' }"
    ></div>
  </div>
</template>

<script>
import { getHomecallTimeStatistical } from "api/home";
// import moment from 'moment';

export default {
  name: "sixBody",
  props: {},
  data() {
    return {
      typeClicksix: "week",
      vaildVos: [
        {
              day: "2021-07-12 00:00:00",
            val: 50,
            valTime: "00:00:00"
        },
        {
            day: "2021-07-13 00:00:00",
            val: 100,
            valTime: "00:00:00"
        },
        {
            day: "2021-07-14 00:00:00",
            val: 99,
            valTime: "00:00:00"
        },
        {
            day: "2021-07-15 00:00:00",
            val: 99,
            valTime: "00:01:39"
        },
      ],
      countVos: [
        {
            day: "2021-07-12 00:00:00",
            val: 0,
            valTime: "00:00:00"
        },
        {
            day: "2021-07-13 00:00:00",
            val: 0,
            valTime: "00:00:00"
        },
        {
            day: "2021-07-14 00:00:00",
            val: 211,
            valTime: "00:03:31"
        },
        {
            day: "2021-07-15 00:00:00",
            val: 172,
            valTime: "00:02:52"
        },
    ]
    };
  },
  methods: {
    //   从父组件调用的
    _getHomecall(val) {
      getHomecallTimeStatistical({ type: val }).then((res) => {
        if (val == "week") {
          this.typeClicksix = "week";
          this.$nextTick(() => {
            this.drawsixLine(res.result);
          });
        } else {
          this.typeClicksix = "month";
          this.$nextTick(() => {
            this.drawsixLinemonth(res.result);
          });
        }
      });
    },
    _getHomecallTimeStatistical() {
      getHomecallTimeStatistical({ type: this.typeClicksix }).then((res) => {
        this.drawsixLine(res.result);
      });
    },
    secondToTimeStr(t) {
      if (!t) return;
      if (t < 60) return "00:00:" + ((i = t) < 10 ? "0" + i : i);
      if (t < 3600) return "00:" + ((a = parseInt(t / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
      if (3600 <= t) {
        var a, i, e = parseInt(t / 3600);
        return (e < 10 ? "0" + e : e) + ":" + ((a = parseInt(t % 3600 / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
      }
    },
    drawsixLine(obj) {
      // let countVos = this.countVos; // 总通时
      // let vaildVos = this.vaildVos; // 有效通时
      let countVos = obj.countVos; // 总通时
      let vaildVos = obj.vaildVos; // 有效通时
      let TimeArray = []; //装x轴时间
      let countVosval = []; //总通时val
      let vaildVosval = []; //有效通时val

      countVos.map((item) => {
        item.day = item.day.slice(5, 10);
        TimeArray.push(item.day);
        countVosval.push(item.val);
      });
      vaildVos.map((i) => {
        vaildVosval.push(i.val);
      });
      // let currentPosY = 0
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChartsix"));
      // 绘制图表
      myChart.setOption({
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
           // 自定义提示框的内容
          formatter: (params) => {
            let result = TimeArray[params[0].dataIndex] + "</br>";
            params.forEach(el => {
              countVos.map((item) => {
                if(item.val == el.data) {
                  el.dataTime = item.valTime
                }
              })
              vaildVos.map((i) => {
                if(i.val == el.data) {
                  el.dataTime = i.valTime
                }
              })
              result += `${el.seriesName}：${el.dataTime}</br>`
            })
            return result;
          }
        },

        xAxis: [
          {
            type: "category",
            data: TimeArray,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
                formatter: (params ) => {
                  if(params <=1 ) {
                    params = 1
                  } 
                  return this.secondToTimeStr(params)
                }
            }
          },
        ],
        series: [
          {
            name: "总通时",
            type: "line",
            // 展示曲线上的点
            showSymbol: true,
            // 让曲线更加圆滑
            smooth: true,
            lineStyle: {
              color: "rgb(40,194,248)",
              normal: {
                color: "rgb(40,194,248)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(40,194,248)",
              }
            },
            // label: {
            //   show: true,
            //   position: "top",
            // },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(40,194,248,0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(40,194,248,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: countVosval,
          },
          {
            name: "有效通时",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(137,119,255)",
              normal: {
                color: "rgb(137,119,255)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(137,119,255)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(137,119,255,0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(137,119,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: vaildVosval,
          },
        ],
      });
    },
    drawsixLinemonth(obj) {
      let countVos = obj.countVos; // 总通时
      let vaildVos = obj.vaildVos; // 有效通时

      let TimeArray = []; //装x轴时间
      let countVosval = []; //总通时val
      let vaildVosval = []; //有效通时val

      countVos.map((item) => {
        item.day = item.day.slice(5, 10);
        TimeArray.push(item.day);
        countVosval.push(item.val);
      });
      vaildVos.map((i) => {
        vaildVosval.push(i.val);
      });
      
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChartsixmonth"));
      // 绘制图表
      myChart.setOption({
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // 自定义提示框的内容
          formatter: (params) => {
            let result = TimeArray[params[0].dataIndex] + "</br>";
            params.forEach(el => {
              countVos.map((item) => {
                if(item.val == el.data) {
                  el.dataTime = item.valTime
                }
              })
              vaildVos.map((i) => {
                if(i.val == el.data) {
                  el.dataTime = i.valTime
                }
              })
              result += `${el.seriesName}：${el.dataTime}</br>`
            })
            return result;
          }
        },

        xAxis: [
          {
            type: "category",
            data: TimeArray,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
                formatter: (params) => {
                  if(params <=1 ) {
                    params = 1
                  }
                  console.log('params', params);
                  return this.secondToTimeStr(params)
                }
            }
          },
        ],
        series: [
          {
            name: "总通时",
            type: "line",
            // 展示曲线上的点
            showSymbol: true,
            // 让曲线更加圆滑
            smooth: true,
            lineStyle: {
              color: "rgb(40,194,248)",
              normal: {
                color: "rgb(40,194,248)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(40,194,248)",
              }
            },
            // label: {
            //   show: true,
            //   position: "top",
            // },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(40,194,248,0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(40,194,248,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: countVosval,
          },
          {
            name: "有效通时",
            type: "line",
            smooth: true,
            showSymbol: true,
            lineStyle: {
              color: "rgb(137,119,255)",
              normal: {
                color: "rgb(137,119,255)",
              },
            },
            itemStyle: {
              normal: {
                color: "rgb(137,119,255)",
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(137,119,255,0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(137,119,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            emphasis: {
              focus: "series",
            },
            data: vaildVosval,
          },
        ],
      });
    },
  },
  mounted() {
    this._getHomecallTimeStatistical();
    // this.drawsixLine();
  },
};
</script>

<style lang="scss" scoped>
.sixBody {
  padding: 10px 20px;
  height: calc(100% - 46px);
}
</style>