<template>
  <div class="fourBody">
    <el-table 
        border
        :header-cell-style="{fontSize: '13px', color:'#000', backgroundColor: '#e5f2ff'}"
        header-cell-class-name="normal-table-header"
        :cell-style="{fontSize: '13px', color:'#666'}"
        :data="tableData" 
        height="260"
        tooltip-effect="dark"
        style="width: 100%">
      <el-table-column :show-overflow-tooltip="true"  prop="name" label="类型" width="70" align="left" > </el-table-column>
      <el-table-column :show-overflow-tooltip="true"  prop="address" label="消息内容" align="left" > </el-table-column>
      <el-table-column :show-overflow-tooltip="true"  prop="date" label="通知时间" width="120" align="left" > </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "fourBody",
  props: {},
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.fourBody {
    padding: 10px 20px;
}
</style>