var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sixBody" }, [
    _vm.typeClicksix == "week"
      ? _c("div", {
          style: { width: "100%", height: "100%" },
          attrs: { id: "myChartsix" }
        })
      : _c("div", {
          style: { width: "100%", height: "100%" },
          attrs: { id: "myChartsixmonth" }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }