<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-29 15:28:40
 * @LastEditors: Seven
 * @LastEditTime: 2021-06-29 15:48:36
-->
<template>
  <div class="threeBody">
    <div class="ListDataclass">
      <ul>
        <li>
          <span class="BorderRadiut raduitColor"></span>
          <span class="DataSpantitle">7天内</span>
          <span class="DataParams">{{Pramlist.withinSevenPer}}</span>
          <div class="Dash"></div>
        </li>
        <li>
          <span class="BorderRadiut raduitColortwo"></span>
          <span class="DataSpantitle">31-60天内</span>
          <span class="DataParams">{{Pramlist.thirtyOneToSixtyPer}}</span>
          <div class="Dash"></div>
        </li>
        <li>
          <span class="BorderRadiut raduitColorthree"></span>
          <span class="DataSpantitle">未联系</span>
          <span class="DataParams">{{Pramlist.notContcatPer}}</span>
          <div class="Dash"></div>
        </li>
        <li>
          <span class="BorderRadiut raduitColorfour"></span>
          <span class="DataSpantitle">8-30天内</span>
          <span class="DataParams">{{Pramlist.eightToThirtyPer}}</span>
          <div class="Dash"></div>
        </li>
        <li>
          <span class="BorderRadiut raduitColorfive"></span>
          <span class="DataSpantitle">60天以上</span>
          <span class="DataParams">{{Pramlist.over60DaysPer}}</span>
        </li>
      </ul>
    </div>
    <div id="myChart" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import { getHomefllowStatistical } from "api/home";

export default {
  name: "threeBody",
  props: {},
  data() {
    return {
      Pramlist: {}
    };
  },
  methods: {
    _getHomefllowStatistical() {
      getHomefllowStatistical().then((res) => {
        const { result } = res;
        this.Pramlist = result
        this.drawLine(result);
      });
    },
    drawLine(obj) {
      // 基于准备好的dom，初始化echarts实例
      let setObj = obj;
      let fromtotal =
        setObj.withinSeven +
        setObj.thirtyOneToSixty +
        setObj.notContcat +
        setObj.eightToThirty +
        setObj.over60Days;
      fromtotal = fromtotal.toString();
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        // legend: {
        //   type: "scroll",
        //   orient: "vertical",
        //   right: 60,
        //   top: 50,
        //   itemHeight: 20
        //   // bottom: 20, 
        //   // borderWidth:2,
        // },
        tooltip: {
          trigger: "item",
          
        },
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "面积模式",
            type: "pie",
            radius: [40, 100],
            center: ["30%", "55%"],
            roseType: "area",
            label: {
              show: false,
              position: "center",
              normal: {
                show: true,
                position: "center",
                textStyle: {
                  fontSize: 20,
                  padding: [-100, 0, 0, 0],
                },
                formatter: fromtotal,
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: setObj.withinSeven ? setObj.withinSeven : 0,
                name: "7天内",
                itemStyle:{color:'#337aff'}
              },
              {
                value: setObj.thirtyOneToSixty ? setObj.thirtyOneToSixty : 0,
                name: "31-60天内",
                itemStyle:{color:'#61ede6'}
              },
              {
                value: setObj.notContcat ? setObj.notContcat : 0,
                name: "未联系",
                itemStyle:{color:'#fedd51'}
              },
              {
                value: setObj.eightToThirty ? setObj.eightToThirty : 0,
                name: "	8-30天内",
                itemStyle:{color:'#daa3ff'}
              },
              {
                value: setObj.over60Days ? setObj.over60Days : 0,
                name: "60天以上",
                itemStyle:{color:'#f98cbc'}
              },
            ],
          },
        ],
      });
    },
  },
  mounted() {
    this._getHomefllowStatistical();
  },
};
</script>

<style lang="scss" scoped>
.threeBody {
  width: 100%;
  padding: 10px 20px;
  height: calc(100% - 46px);
  position: relative;
  .ListDataclass {
    width: 48%;
    position: absolute;
    right: 18px;
    top: 28px;
    z-index: 20;
    > ul {
      width: 100%;
      display:flex;
      flex-direction:column;
      justify-content: center;
      li {
        padding: 4px 10px;
        .BorderRadiut {
          width: 18px;
          height: 18px;
          background-color: #fff;
          // border: 6px solid #337aff;
          border-radius: 50%;
          display: inline-block;
          float: left;
          margin-top: 4px;
        }
        .raduitColor {
          border: 6px solid #337aff;
        }
        .raduitColortwo {
          border: 6px solid #61ede6;
        }
        .raduitColorthree {
          border: 6px solid #fedd51;
        }
        .raduitColorfour {
          border: 6px solid #daa3ff;
        }
        .raduitColorfive {
          border: 6px solid #f98cbc;
        }
        .DataSpantitle {
          padding-left: 12px;
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          width: 45%;
          color: #181818;
          display: inline-block;
        }
        .DataParams {
          font-size: 14px;
          font-weight: 700;
          color: #181818;
          width: 45%;
          display: inline-block;
          text-align: right;
        }
        .Dash {
          opacity: 1;
          background: #ffffff;
          width: 100%;
          border: 1px dashed #e1e4eb;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>