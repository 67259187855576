<!--
 * @Description: 首页
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-27 09:16:38
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-11 09:39:46
-->
<template>
  <div class="home">
    <el-row :gutter="24">
      <el-col :span="18" :offset="3">
        <div class="Editioncenter">
          <el-row :gutter="24">
            <el-col :span="12" v-if="permUtil.WbHome.Homeone()">
              <div class="grid-content bg-purple topone">
                <HomeTitle :title="onetitle" :showTimetitle="true" />
                <div class="my-content-field">
                  <div class="left-info">
                    <div class="welcome">{{ welcome }}</div>
                    <div class="time-line">
                      <el-timeline>
                        <el-timeline-item
                          size="normal"
                          v-for="(activity, index) in activities"
                          :key="index"
                          type="primary"
                          :timestamp="activity.timestamp"
                        >
                          {{ activity.content }}
                        </el-timeline-item>
                      </el-timeline>
                    </div>
                  </div>

                  <ul class="right-info">
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.currentBizCount }}
                      </p>
                      <p>当前客户数</p>
                    </li>
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.reserveBizCount }}
                      </p>
                      <p>留存客户数</p>
                    </li>
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.todayReceiverBizCount }}
                      </p>
                      <p>今日领取客户</p>
                    </li>
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.todayValidCallCount }}
                      </p>
                      <p>今日有效通话数</p>
                    </li>
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.todayFollowBizCount }}
                      </p>
                      <p>今日跟进动态数</p>
                    </li>
                    <li>
                      <p class="count">
                        {{ homestatisticsBiz.todayAppointBizCount }}
                      </p>
                      <p>今日预约客户数</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="grid-content bg-purple topone">
                <div class="rank-field">
                  <div class="rank-head">
                    <el-row :gutter="24">
                      <el-col :span="3">
                        <span class="first">筛选</span>
                      </el-col>
                      <el-col :span="5">
                        <el-select
                          size="small"
                          v-model="valueslectOne"
                          placeholder="请选择"
                          @change="SetChangeopt"
                        >
                          <el-option
                            v-for="item in optionsDateone"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="8">
                        <div class="datePicker" 
                          @mouseover="HoverTimeshow"
                          @mouseleave="HoverTimeleave">
                          <div v-if="showTimeBox" class="showTimeBox">{{paramsSet.valueslectOne}} 至 {{paramsSet.endTimedate}}</div>
                          <el-date-picker
                            v-if="valueslectOne == 1"
                            v-model="valuedateOne"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            @change="DateTimeChange"
                          >
                          </el-date-picker>
                          <el-date-picker
                            v-else-if="valueslectOne == 2"
                            v-model="valuedateOne"
                            type="week"
                            size="small"
                            :format="paramsSet.valueslectOne + ' ' + paramsSet.endTimedate"
                            placeholder="选择周"
                            @change="DateTimeChange"  
                          >
                          </el-date-picker>
                          <el-date-picker
                            v-else-if="valueslectOne == 3"
                            v-model="valuedateOne"
                            type="month"
                            size="small"
                            value-format="yyyy-MM"
                            placeholder="选择月"
                            @change="DateTimeChange"
                          >
                          </el-date-picker>
                          <Quarter
                            v-else-if="valueslectOne == 4"
                            @DateGetSeasonset="DateGetSeason"
                          />
                          <!-- <el-date-picker
                            v-else-if="valueslectOne == 4"
                            v-model="valuedateOnejidu"
                            size="small"
                            type="month"
                            placeholder="选择季度"
                            @change="DateTimeChange"
                          >
                          </el-date-picker> -->
                          <el-date-picker
                            v-else
                            v-model="valuedateOne"
                            type="year"
                            size="small"
                            value-format="yyyy"
                            placeholder="选择年"
                            @change="DateTimeChange"
                          >
                          </el-date-picker>
                        </div>
                      </el-col>
                      <el-col :span="7">
                        <v-form ref="form" lazy-validation>
                          <SelectTree
                            ref="deptsys"
                            :data="deptListtree.option"
                            label="deptName"
                            value="deptId"
                            :showhometree="true"
                            @update:select="selectChangetree"
                            v-bind:select.sync="form.deptId"
                          />
                        </v-form>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="leftTitleRank" v-if="permUtil.WbHome.Homeqinfen()">勤奋榜</div>
                  <div class="left-rank" v-if="permUtil.WbHome.Homeqinfen()">
                    <ul class="rank-contenttop">
                      <li class="t-header">
                        <span class="first-column">排名</span>
                        <!-- <span class="second-column">前3名</span> -->
                        <el-dropdown @command="ClickDropdownone">
                          <span class="el-dropdown-link">
                            {{ dropNameone
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1"
                              >前10名</el-dropdown-item
                            >
                            <el-dropdown-item command="2"
                              >前50名</el-dropdown-item
                            >
                            <el-dropdown-item command="3"
                              >所有</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                        <span class="third-column">呼叫数</span>
                      </li>
                    </ul>
                    <ul class="rank-content">
                      <li
                        class="t-body"
                        v-for="(item, index) in diligentList"
                        :key="item.id"
                        :class="[
                          index > 2 ? 'rank-less-third' : '',
                          index == 0 ? 'firstchildtext' : '',
                        ]"
                      >
                        <span
                          class="first-column"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <span v-if="index > 2">{{ index + 1 }}</span>
                          <img
                            v-if="index == 0"
                            src="../../assets/image/zuone.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                          <img
                            v-if="index == 1"
                            src="../../assets/image/zutwo.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                          <img
                            v-if="index == 2"
                            src="../../assets/image/zuthree.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                        </span>
                        <span class="second-column">{{ item.userName }}</span>
                        <span class="third-column">{{ item.dataCount }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="leftTitleRank" v-if="permUtil.WbHome.Homedongtai()">动态榜</div>
                  <div class="right-rank" v-if="permUtil.WbHome.Homedongtai()">
                    <ul class="rank-contenttop">
                      <li class="t-header">
                        <span class="first-column">排名</span>
                        <el-dropdown @command="ClickDropdown">
                          <span class="el-dropdown-link">
                            {{ dropName
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1"
                              >前10名</el-dropdown-item
                            >
                            <el-dropdown-item command="2"
                              >前50名</el-dropdown-item
                            >
                            <el-dropdown-item command="3"
                              >所有</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                        <!-- <span class="second-column">前3名</span> -->
                        <span class="third-column">跟进次数</span>
                      </li>
                    </ul> 
                    <ul class="rank-content">
                      <li
                        class="t-body"
                        v-for="(item, index) in dynamicList"
                        :class="[
                          index > 2 ? 'rank-less-third' : '',
                          index == 0 ? 'firstchildtext' : '',
                        ]"
                        :key="item.index"
                      >
                        <span
                          class="first-column"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <span v-if="index > 2">{{ index + 1 }}</span>
                          <img
                            v-if="index == 0"
                            src="../../assets/image/zuone.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                          <img
                            v-if="index == 1"
                            src="../../assets/image/zutwo.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                          <img
                            v-if="index == 2"
                            src="../../assets/image/zuthree.png"
                            style="
                              display: inline-block;
                              width: 20px;
                              height: 20px;
                            "
                            alt=""
                          />
                        </span>
                        <span class="second-column">{{ item.userName }}</span>
                        <span class="third-column">{{ item.dataCount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </el-col>

            <el-col :span="12" v-if="permUtil.WbHome.Homelianxi()">
              <div class="grid-content bg-purple topone">
                <HomeTitle title="联系情况分析" />
                <ThreeBody />
              </div>
            </el-col>
            <el-col :span="12" v-if="permUtil.WbHome.Homexiaoxi()">
              <div class="grid-content bg-purple topone">
                <HomeTitle title="我的消息" />
                <!-- <FourBody /> -->
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple topone">
                <Grade />
              </div>
            </el-col>

            <el-col :span="12" v-if="permUtil.WbHome.Homehujiao()">
              <div class="grid-content bg-purple topone">
                <HomeTitle
                  :showRightbox="true"
                  title="呼叫/动态"
                  @getFivecallClick="getFivecallClick"
                />
                <FiveBody ref="ChildFive" /></div
            ></el-col>
            <el-col :span="12" v-if="permUtil.WbHome.Hometonghua()">
              <div class="grid-content bg-purple topone">
                <HomeTitle
                  :showRightboxphone="true"
                  title="通话"
                  @getSixcallClick="getSixcallClick"
                />
                <SixBody ref="Childsix" />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 绑定零一裂变 -->
    <HqModal :visible="shoLingyimodal" title="绑定零一裂变用户" width="580" @on-cancel="cancellingyi">
      <!-- <v-autocomplete
        v-model="transferForm.lingyiUserId"
        :items="LingyiList"
        :menu-props="{ bottom: true, offsetY: true }"
        label="零一裂变用户"
        item-text="nickName"
        item-value="userId"
        outlined
        :dense="true"
        :height="32"
        :clearable="true"
        clear-icon="$clear"
        hide-details="auto"
      >
        <template v-slot:item="slotProps">
          <img class="selectImg" :src="slotProps.item.avatar" alt="">
          {{slotProps.item.nickName}}
        </template>
      </v-autocomplete> -->
      <el-row style="width: 94%;padding-left:24px;">
        <el-col :span="10">
          <v-text-field
              v-model="nickName"
              label="用户名"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
        </el-col>
        <el-col :span="12">
          <el-button class="searchBtn" @click="searchUsernc">查询</el-button>
          <el-button class="searchBtn" @click="resetFn">重置</el-button>
        </el-col>
      </el-row>
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-table
          highlight-current-row
          ref="multipleTable"
          :data="LingyiList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRownc"
        >
          <el-table-column prop="avatar" label="头像" align="center">
            <template slot-scope="scope">
              <img class="selectImg" :src="scope.row.avatar" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="nickName" label="用户名" align="center"></el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParams.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParams.pageSize"
            :total="total"
          />
        </div>
        </el-col>
      </el-row>

      <div slot="footer">
        <el-button @click="cancellingyi">取消</el-button>
        <el-button @click="savelingyi" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 提示 -->
    <HqModal :visible="shoLingyimodalwarn" title="绑定零一裂变用户提示" width="580" @on-cancel="cancelwarnx">
      <span style="color: #2db7f5">您的用户暂未绑定零一裂变用户【微信】，请到用户中心绑定个人零一裂变用户！</span>
      <br />
      <span style="color: #2db7f5">没绑定零一裂变的用户分享获取到的商机将随机分配，如果绑定了全网用户,
        <br/>
        则本人分享出去的零一裂变活动，裂变回来的用户将分配给本人进行跟进！
      </span>
      <div slot="footer">
        <el-button @click="dingingyi" type="primary">去绑定</el-button>
        <el-button @click="cancelwarn">不再提示</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
// import { requestUserInfo, reqsysDeptTree } from "api/login/login-api";
import { mapGetters } from "vuex";
import merge from "webpack-merge";
import HomeTitle from "./components/HomeTitle";
import FourBody from "./components/fourBody";
import ThreeBody from "./components/threeBody";
import FiveBody from "./components/fiveBody";
import SixBody from "./components/sixBody";
import SelectTree from "./components/select-tree";
import {  reqworkbenchDeptTree  } from "@/api/public";
import Quarter from "./components/Quarter";
import Grade from "./components/grade.vue";

import {
  getHomestatisticsBiz,
  getHomediligent,
  getHomedynamic,
  getLingyiUserList,
  getDisLingyiUser,
  getDislingyiWarn,
  getLingyiwarn
} from "api/home";

export default {
  name: "home",
  components: {
    HomeTitle,
    FourBody,
    ThreeBody,
    FiveBody,
    SixBody,
    SelectTree,
    Quarter,
    // hqPicker,
    Grade,
  },
  data() {
    return {
      transferForm: {},
      LingyiList: [],
      shoLingyimodal: false,
      shoLingyimodalwarn:false,
      showTimeBox: false,
      dropName: "前10名",
      dropNameone: "前10名",
      dataLinttwo: "",
      dataLintone: "",
      form: {
        deptId: "",
      },
      valuedateOne: "",
      optionsDateone: [
        {
          label: "日",
          value: 1,
        },
        {
          label: "周",
          value: 2,
        },
        {
          label: "月",
          value: 3,
        },
        {
          label: "季度",
          value: 4,
        },
        {
          label: "年",
          value: 5,
        },
      ],
      valueslectOne: 1,
      onetitle: "我的地盘",
      hoursTip: "",
      welcome: "林悦斌",
      activities: [
        {
          content: "客户跟进",
        },
        {
          content: "预约校区",
        },
        {
          content: "报名下单",
        },
      ],
      rankList: [
        {
          id: 1,
          seq: 1,
          name: "张无忌",
          count: "99",
        },
        {
          id: 2,
          seq: 2,
          name: "乔峰",
          count: "90",
        },
        {
          id: 3,
          seq: 3,
          name: "张无忌",
          count: "89",
        },
      ],
      queryParam: {},
      deptSelectData: {},
      select: "",
      aaaa: [],
      homestatisticsBiz: {}, //我的地盘
      diligentList: [], //勤奋排行榜
      dynamicList: [], //动态排行榜
      deptListtree: {
        option: [],
      },
      paramsSet: {
        deptId: "",
        valueslectOne: "", //开始
        endTimedate: "", //结束
      },
      valuedateOnejidu: "第一季度",
      pageParams: {
          pageSize: 10,
          pageNum: 1,
        },
        total: 0,
        nickName: '',
        localre: {},
        listLoading: false,
    };
  },
  computed: {
    ...mapGetters(["loginUser"]),
  },
  created() {
    },
  mounted() {
    this._getLingyiwarn()
    this._getHomestatisticsBiz();
    this._getHomediligent();
    this._getHomedynamic();
    this._getDeptList();
    this.GetDayhomehour();
    this.Gethometrack()
    this._getLingyiUserList()
    let Daysmounted = this.getNowFormatDate();
    this.valuedateOne = this.getNextDate(Daysmounted, -1);
    this.welcome = this.loginUser.userName;

    
    // console.log("this.vuex", this.loginUser, this.form);
    if (this.$route.query.token) {
      this.$router.push({
        query: merge({}, {}),
      });
    }
  },
  methods: {
    _getLingyiwarn() {
      getLingyiwarn().then(res =>{
        if (res.result == true) {
          this.shoLingyimodalwarn = true
        } else {
          this.shoLingyimodalwarn = false
        }
      })
    },
    searchUsernc() {
      this.pageParams.pageNum = 1
      this._getLingyiUserList()
    },
    resetFn() {
      this.nickName = ''
      this.pageParams.pageNum = 1
      this._getLingyiUserList()
    },
    clickRownc(val) {
      this.localre = val
      console.log('this.localre', this.localre);
    },
    currentChange(val) {
       this.pageParams.pageNum = val
      this._getLingyiUserList()
    },
    sizeChange(val) {
      this.pageParams.pageSize = val
      this._getLingyiUserList()
    },
    // 获取零一裂变用户
    async _getLingyiUserList() {
      const { result, msg } = await getLingyiUserList(this.pageParams, this.nickName?this.nickName:'')
      console.log('data零一裂变', msg, result);
      this.LingyiList = result.list
      this.total = result.totalCount
    },
    showBInglingyi() {
      this.shoLingyimodal = true
      console.log('this.shoLingyimodal',this.shoLingyimodal);
    },
    cancellingyi() {
      this.shoLingyimodal = false
    },
    savelingyi() {
      console.log(this.transferForm.lingyiUserId);
      this.transferForm.lingyiUserId = this.localre.userId
      getDisLingyiUser(this.transferForm.lingyiUserId !== undefined ? this.transferForm.lingyiUserId : '').then(res=> {
        if(res.code == 0) {
          this.$message.success(res.msg)
          this.shoLingyimodal = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    cancelwarnx() {
      this.shoLingyimodalwarn = false
    },
    // 不再提示
    cancelwarn() {
      getDislingyiWarn().then(res => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.shoLingyimodalwarn = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 去绑定
    dingingyi() {
      this.shoLingyimodal = true
      this.shoLingyimodalwarn = false
    },
    // ...mapActions(["getUserInfo"])
    // 判断当天时间是否是上下午晚上
    GetDayhomehour() {
      let date = new Date();
      if (date.getHours() >= 0 && date.getHours() < 12) {
        this.hoursTip = ", 上午好";
      } else if (date.getHours() >= 12 && date.getHours() < 18) {
        this.hoursTip = ", 下午好";
      } else {
        this.hoursTip = ", 晚上好";
      }
    },
    // 获取是否登陆进来是否是什么项目
    Gethometrack() {
      let type = this.loginUser.trackType
      switch (type) {
        case 0:
          this.form.deptId = this.loginUser.deptId
          break;
        case 1:
           this.form.deptId = 2177
           break;
        case 2:
          this.form.deptId = 1001
          break;
        case 3:
          this.form.deptId = 2149
          break;
        case 4:
          this.form.deptId = 2267
          break;
        default:
          this.form.deptId = this.loginUser.deptId
      }
    },
    HoverTimeshow() {
      if(this.valueslectOne == 2) {
        if(this.paramsSet.valueslectOne !== "") {
          this.showTimeBox = true
        }
      }
    },
    HoverTimeleave() {
      this.showTimeBox = false
    },
    // 动态榜单
    ClickDropdown(val) {
      if (val == 1) {
        this.dropName = "前10名";
        this.dataLinttwo = 10;
      } else if (val == 2) {
        this.dropName = "前50名";
        this.dataLinttwo = 50;
      } else {
        this.dropName = "所有";
        this.dataLinttwo = -1;
      }
      this._getHomedynamic();
    },
    // 勤奋榜
    ClickDropdownone(val) {
      if (val == 1) {
        this.dropNameone = "前10名";
        this.dataLintone = 10;
      } else if (val == 2) {
        this.dropNameone = "前50名";
        this.dataLintone = 50;
      } else {
        this.dropNameone = "所有";
        this.dataLintone = -1;
      }
      this._getHomediligent();
    },
    selectChangetree(deptId) {
      this.paramsSet.deptId = deptId;
      this._getHomediligent();
      this._getHomedynamic();
    },
    SetChangeopt(val) {
      this.valueslectOne = val;
      this.valuedateOne = "";
      this.paramsSet.valueslectOne = "";
      this.paramsSet.endTimedate = "";
    },
    DateGetSeason(val) {
      let dateMonth = val.slice(5, 6);
      let Dyear = val.slice(0, 4);
      if (dateMonth == 1) {
        this.paramsSet.valueslectOne = `${Dyear}-01-01`;
        this.paramsSet.endTimedate = `${Dyear}-03-31`;
      } else if (dateMonth == 2) {
        this.paramsSet.valueslectOne = `${Dyear}-04-01`;
        this.paramsSet.endTimedate = `${Dyear}-06-30`;
      } else if (dateMonth == 3) {
        this.paramsSet.valueslectOne = `${Dyear}-07-01`;
        this.paramsSet.endTimedate = `${Dyear}-09-30`;
      } else {
        this.paramsSet.valueslectOne = `${Dyear}-10-01`;
        this.paramsSet.endTimedate = `${Dyear}-12-31`;
      }
      this._getHomediligent();
      this._getHomedynamic();
    },
    DateTimeChange(val) {
      // 如果是季度的选择的话
      if (this.valueslectOne == 4) {
        console.log("")
      } else if (this.valueslectOne == 5) {
        this.paramsSet.valueslectOne = `${val}-01-01`;
        this.paramsSet.endTimedate = `${val}-12-31`;
      } else if (this.valueslectOne == 3) {
        let codeMonth = val.slice(5, 7);
        if (
          codeMonth == "01" ||
          codeMonth == "03" ||
          codeMonth == "05" ||
          codeMonth == "07" ||
          codeMonth == "08" ||
          codeMonth == "12"
        ) {
          this.paramsSet.valueslectOne = `${val}-01`;
          this.paramsSet.endTimedate = `${val}-31`;
        } else if (codeMonth == "02") {
          this.paramsSet.valueslectOne = `${val}-01`;
          this.paramsSet.endTimedate = `${val}-31`;
        } else {
          this.paramsSet.valueslectOne = `${val}-01`;
          this.paramsSet.endTimedate = `${val}-30`;
        }
      } else if (this.valueslectOne == 2) {
        let timeStamp = val.getTime();
        this.paramsSet.valueslectOne = this.funDate(
          timeStamp - 24 * 60 * 60 * 1000
        ); //开始时间
        this.paramsSet.endTimedate = this.funDate(
          timeStamp + 24 * 60 * 60 * 1000 * 5
        ); //结束时间
      } else {
        this.paramsSet.valueslectOne = val;
      }
      this.valuedateOne = val;
      // this.paramsSet.valueslectOne = val
      this._getHomediligent();
      this._getHomedynamic();
    },
    //时间戳转为yy-mm-dd
    funDate(unixtimestamp) {
      let temp = new Date(unixtimestamp);
      let year = 1900 + temp.getYear();
      let month = "0" + (temp.getMonth() + 1);
      let date = "0" + temp.getDate();
      // let hour = "0" + temp.getHours();
      // let minute = "0" + temp.getMinutes();
      // let second = "0" + temp.getSeconds();
      return (
        year +
        "-" +
        month.substring(month.length - 2, month.length) +
        "-" +
        date.substring(date.length - 2, date.length)
      );
    },
    // 获取部门树
    _getDeptList() {
      reqworkbenchDeptTree().then((data) => {
        this.deptListtree.option = data.result
      });
    },
    // 勤奋排行榜接口
    _getHomediligent() {
      let Days = this.getNowFormatDate();
      let params = {
        dataLimit: this.dataLintone ? this.dataLintone : 10,
        deptId: this.paramsSet.deptId ? this.paramsSet.deptId : "",
        startTime: this.paramsSet.valueslectOne
          ? this.paramsSet.valueslectOne
          : this.getNextDate(Days, -1),
        timeEnd: this.paramsSet.endTimedate ? this.paramsSet.endTimedate : "",
        // timeEnd: "2021-07-02",
      };
      getHomediligent(params).then((res) => {
        this.diligentList = res.result;
      });
    },
    // 动态排行榜
    _getHomedynamic() {
      let Days = this.getNowFormatDate();
      let params = {
        dataLimit: this.dataLinttwo ? this.dataLinttwo : 10,
        deptId: this.paramsSet.deptId ? this.paramsSet.deptId : "",
        startTime: this.paramsSet.valueslectOne
          ? this.paramsSet.valueslectOne
          : this.getNextDate(Days, -1),
        timeEnd: this.paramsSet.endTimedate ? this.paramsSet.endTimedate : "",
        // timeEnd: "2021-07-02",
      };
      getHomedynamic(params).then((res) => {
        this.dynamicList = res.result;
      });
    },
    // 获取当天日期的前一天
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    // 获取当天的时间
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 我的地盘接口
    _getHomestatisticsBiz() {
      getHomestatisticsBiz().then((res) => {
        this.homestatisticsBiz = res.result;
      });
    },
    // 呼叫动态点击事件
    getFivecallClick(val) {
      this.$refs.ChildFive._getHomecall(val);
    },
    // 通话点击事件
    getSixcallClick(val) {
      this.$refs.Childsix._getHomecall(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.selectImg {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align:middle;
  border-radius: 50%;
}
.el-input__inner {
  width: 100px !important;
  height: 28px !important;
}
.el-date-editor.el-input {
  width: 100%;
}
::v-deep .el-timeline-item__tail {
  border-left: 1px dashed #337aff !important;
}
::v-deep .el-timeline-item__content {
  font-size: 14px !important;
}
::v-deep .el-dropdown {
  width: 33.33% !important;
  height: 30px !important;
}
::v-deep .el-dropdown-link {
  width: 100% !important;
  display: inline-block !important;
}
::v-deep .el-dropdown-selfdefine {
  width: 100% !important;
  display: inline-block !important;
}

.home {
  background: #f5f5fa;
  padding-bottom: 20px;
  .Editioncenter {
    margin: 0 auto;
    .grid-content {
      // width: 747px;
      margin-right: 12px;
      background: #ffffff;
    }
    .topone {
      height: 317px;
      margin-top: 12px;
      background: #ffffff;
    }
  }
}

::v-deep .el-timeline {
  padding-left: 20px;
  .el-timeline-item__content {
    font-size: 12px;
    color: #27457e;
    font-weight: bold;
  }
  .el-timeline-item__node--normal {
    border: 2px solid #337aff;
    background: #fff;
  }
}

.my-content-field {
  background: url("../../assets/image/homemengbancolor.png") no-repeat;
  background-size: 320px 100px;
  background-position: 0px 176px;
  .welcome {
    margin-top: 6px;
    margin-bottom: 20px;
    padding-right: 10px;
    height: 32px;
    max-width: 145px;
    background: url("../../assets/image/homeToptilte.png") no-repeat;
    font-size: 13px;
    line-height: 32px;
    width: 83%;
    color: #fff;
    font-weight: bold;
    padding-left: 6px;
    background-size: cover;
    display: inline-block;
  }

  display: flex;
  .left-info {
    width: 26%;
  }
  .right-info {
    padding: 5px 0 0;
    display: flex;
    width: 74%;
    // flex: 1;
    flex-wrap: wrap;
    li {
      margin-left: 5px;
      margin-bottom: 5px;
      height: 130px;
      border: 1px solid #4686ff;
      width: 31%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #bbb;
      background-color: #fff;
      box-sizing: border-box;
      .count {
        font-size: 28px;
        font-weight: bold;
        color: #3366ff;
        margin-top: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }
}

.rank-field {
  // display: flex;
  height: 100%;

  background-color: #fff;
  .rank-head {
    width: 100%;
    background: #f6faff;
    height: 42px;
    line-height: 42px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding-right: 10px;
    padding-left: 14px;
    margin-bottom: 2px;
    .first {
      font-size: 16px;
      color: #181818;
      font-weight: bold;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      background-color: #00000000;
    }
    p {
      // background: red;
      span {
        background: #fff;
        padding: 2px 4px;
      }
    }
  }
  .leftTitleRank {
    width: 4%;
    height: 272px;
    background: #dbedff;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    color: #337aff;
    display: inline-block;
    float: left;
    text-align: center;
    padding-top: 70px;
    line-height: 40px;
  }
  .left-rank {
    margin-right: 6px;
  }
  // .right-rank {
  //   margin-left: 6px;
  // }

  .left-rank,
  .right-rank {
    float: left;
    width: 45%;
    background-color: #fff;
    // .rank-head {
    //   background: #f6faff;
    //   height: 30px;
    //   line-height: 30px;
    //   display: flex;
    //   justify-content: space-between;
    //   font-size: 12px;
    //   padding-right: 10px;
    //   padding-left: 14px;
    //   margin-bottom: 2px;
    //   .first {
    //     font-size: 14px;
    //     color: #000;
    //     font-weight: bold;
    //     background-color: #00000000;
    //   }
    //   p {
    //     // background: red;
    //     span {
    //       background: #fff;
    //       padding: 2px 4px;
    //     }
    //   }
    // }
    .rank-contenttop {
      padding-left: 0;
      position: relative;
      height: 30px;
      .t-header {
        font-size: 12px;
        line-height: 30px;
        font-weight: bold;
        background: #e5f2ff;
        position: absolute;
        display: inline-block;
        width: 100%;
        margin-bottom: 12px;
        span {
          width: 33.33%;
          display: inline-block;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .rank-content {
      padding-left: 0;
      height: 242px;
      overflow-y: scroll;
      position: relative;
      .t-header {
        font-size: 12px;
        line-height: 30px;
        font-weight: bold;
        background: #e5f2ff;
        position: absolute;
        display: inline-block;
        width: 100%;
        margin-bottom: 12px;
        span {
          width: 33.33%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .t-body {
        height: 28px;
        line-height: 28px;
      }
      .t-body.firstchildtext {
        margin-top: 0px !important;
      }
      .t-body.rank-less-third {
        color: #999;
      }
      .t-body:nth-child(odd) {
        background: #f8f9fb;
      }

      .t-header,
      .t-body {
        font-size: 12px;
        color: #333;
        display: flex;
        span {
          text-align: center;
          flex: 1;
        }

        // .first-column {
        //   width: 40px;
        // }
        // .third-column {
        //   width: 40px;
        // }
      }
    }
  }
}
.second-column {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.showTimeBox {
  position: absolute;
  z-index: 30;
  top: 37px;
  background-color: #fff;
  padding:0px 5px;
}
</style>
